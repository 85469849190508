import { useState, useEffect } from "react";
import styled from "styled-components";
import apartmentsImg from "../../src/assets/images/apartments.png";
import villasImg from "../../src/assets/images/villas.png";
import townhousesImg from "../../src/assets/images/townhouses.png";
import penthouseImg from "../../src/assets/images/penthouse.png";
import officesImg from "../../src/assets/images/offices.png";
import warehouseImg from "../../src/assets/images/warehouse.png";

// Projects Data Structure
const projects = {
  Residential: [
    {
      name: "Apartments",
      details:
        "Apartments are individual units in a building or complex, typically part of multi-story structures. They range from studio flats to larger multi-bedroom units, offering shared amenities like gyms, pools, and sometimes concierge services.",
      clients: 8,
      projects: 12,
    },
    {
      name: "Villas",
      details:
        "Villas are standalone, luxurious homes, often situated in exclusive neighborhoods. They come with private gardens, pools, and spacious layouts, designed for those seeking privacy and high-end living spaces.",
      clients: 6,
      projects: 10,
    },
    {
      name: "Town Houses",
      details:
        "Townhouses are multi-level homes, often connected to others in a row. These homes offer a blend of community living with private outdoor spaces, ideal for families wanting more space than an apartment provides.",
      clients: 5,
      projects: 8,
    },
    {
      name: "Penthouse",
      details:
        "Penthouses are premium units located at the top floors of residential buildings. Known for their expansive views, high-end finishes, and private outdoor terraces, penthouses offer an elite living experience in the city.",
      clients: 3,
      projects: 5,
    },
  ],
  Commercial: [
    {
      name: "Office",
      details:
        "Office spaces range from small units for startups to large corporate buildings, offering flexible layouts, meeting rooms, and business amenities. These spaces cater to companies of various sizes, providing an environment suited for productivity and collaboration.",
      clients: 15,
      projects: 25,
    },
    {
      name: "Industrial",
      details:
        "Industrial properties are designed for manufacturing, storage, and distribution purposes. They include warehouses, factories, and logistics centers, often located in business parks or close to major transport routes for easy accessibility.",
      clients: 10,
      projects: 15,
    },
  ],
};

// Project Images
const projectImages = {
  Apartments: [apartmentsImg, apartmentsImg, apartmentsImg],
  Villas: [villasImg, villasImg],
  "Town Houses": [townhousesImg, townhousesImg],
  Penthouse: [penthouseImg, penthouseImg],
  Office: [officesImg, officesImg],
  Industrial: [warehouseImg, warehouseImg],
};

// Styled Components (same as before)
const Container = styled.div`
  background: linear-gradient(135deg, #1b1b1b, #444444);
  padding: 2rem;
  border-radius: 8px;
  max-width: 80%;
  color: #fff;
  padding-left: 5rem;
  position: relative;
  margin-bottom: 10rem;
  margin: 0 auto;

  @media (max-width: 768px) {
    padding-left: 2rem;
    margin-bottom: 5rem;
  }
`;

const RotatingCircle = styled.div`
  position: absolute;
  top: -2rem;
  right: -2rem;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e2c7a6;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
    top: -1.5rem;
    right: -1.5rem;
  }
`;

const CircleText = styled.p`
  font-size: 2rem;
  color: #fff;
  line-height: 1;

  @media (max-width: 768px) {
    font-size: 1.5rem;
  }
`;

const ProjectGrid = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ProjectColumn = styled.div`
  flex: 1;
  margin-right: 2rem;

  @media (max-width: 768px) {
    margin-right: 0;
    margin-bottom: 2rem;
  }
`;

const ProjectName = styled.div`
  position: relative;
  color: ${(props) => (props.isSelected ? "#FFF" : "#AAA")};
  font-family: ${(props) => (props.isSelected ? "Paris Avenue" : "Poppins")};
  font-size: 1.5rem;
  font-weight: 200;
  cursor: pointer;
  padding: 0.1rem 0;
  margin-top: 0.1rem;

  &:hover span {
    width: 100%;
  }

  span {
    position: absolute;
    bottom: 0.5rem;
    left: 0;
    height: 1px;
    width: ${(props) => (props.isSelected ? "100%" : "0%")};
    background-color: #fff;
    transition: width 0.3s ease;
  }
`;

const SectionTitle = styled.h3`
  color: #fff;
  font-size: 1.3rem;
  margin-bottom: 1rem;
  font-family: "Poppins";
`;

const DetailsContainer = styled.div`
  flex: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const ImageContainer = styled.div`
  background: linear-gradient(135deg, #1b1b1b, #444444);
  padding: 1rem;
  border-radius: 8px;
  max-width: auto;
  height: 20rem;
  color: #fff;
  margin-top: 5rem;
  width: 100%;
  text-align: center;
  align-items: center;
  display: flex;
  justify-content: center;

  img {
    margin-top: -15px;
    width: auto;
    height: 22rem;
    object-fit: cover;
    border-radius: 8px;
  }

  @media (max-width: 768px) {
    max-width: 100%;
    margin-top: 2rem;
    img {
      width: auto;
      height: 10rem;
    }
  }
`;

const ProjectDetails = styled.p`
  color: #fff;
  margin-top: 2rem;
  font-family: Poppins;
  font-size: 1rem;
  font-weight: 200;
  text-align: center;
`;

const InfoStats = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  margin-top: 0.5rem;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: #fff;
    }

    span {
      font-size: 3rem;
      font-weight: 300;
      color: #fff;
      margin: 0;
    }
  }
`;

export default function ProjectSelector() {
  const [selectedSection, setSelectedSection] = useState("Residential");
  const [selectedProject, setSelectedProject] = useState("Apartments");
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  // Get the selected project details
  const selectedDetails = projects[selectedSection].find(
    (p) => p.name === selectedProject
  );

  // Reset the current image index when the project is selected
  useEffect(() => {
    setCurrentImageIndex(0);
  }, [selectedProject]);

  // Set up image slideshow interval
  useEffect(() => {
    const interval = setInterval(() => {
      const imagesForProject = projectImages[selectedProject];
      if (imagesForProject) {
        setCurrentImageIndex((prev) => (prev + 1) % imagesForProject.length);
      }
    }, 3000);

    // Clear interval when component unmounts or selected project changes
    return () => clearInterval(interval);
  }, [selectedProject]);

  const imagesForProject = projectImages[selectedProject] || [];

  return (
    <Container id="services">
      <RotatingCircle>
        <CircleText></CircleText>
      </RotatingCircle>

      <ProjectGrid>
        <ProjectColumn>
          <h2
            style={{ color: "#FFF", fontSize: "0.90rem", marginBottom: "3rem" }}
          >
            CATEGORIES
          </h2>

          <SectionTitle>RESIDENTIAL</SectionTitle>
          {projects["Residential"].map((project, index) => (
            <ProjectName
              key={index}
              onClick={() => {
                setSelectedSection("Residential");
                setSelectedProject(project.name);
              }}
              isSelected={selectedProject === project.name}
            >
              {project.name}
              <span />
            </ProjectName>
          ))}

          <SectionTitle>COMMERCIAL</SectionTitle>
          {projects["Commercial"].map((project, index) => (
            <ProjectName
              key={index}
              onClick={() => {
                setSelectedSection("Commercial");
                setSelectedProject(project.name);
              }}
              isSelected={selectedProject === project.name}
            >
              {project.name}
              <span />
            </ProjectName>
          ))}
        </ProjectColumn>

        <DetailsContainer>
          <ImageContainer>
            {imagesForProject.length > 0 ? (
              <img
                src={imagesForProject[currentImageIndex]}
                alt={selectedProject}
              />
            ) : (
              <p>No image available</p>
            )}
          </ImageContainer>

          <ProjectDetails>{selectedDetails?.details}</ProjectDetails>

          <InfoStats>
            <div>
              <span>{selectedDetails?.clients}</span>
              <p>clients</p>
            </div>
            <div>
              <span>{selectedDetails?.projects}</span>
              <p>projects</p>
            </div>
          </InfoStats>
        </DetailsContainer>
      </ProjectGrid>
    </Container>
  );
}
