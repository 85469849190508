import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Text } from "@mantine/core";
import { IconMenu2, IconX } from "@tabler/icons-react";
import brandname from "../../assets/images/Wordmark.png";
import BrandLogo from "../../assets/images/Logo.png";

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  padding: 1rem 7rem;
  position: fixed;
  background-color: transparent;
  transition: background-color 0.3s ease;
  z-index: 100;

  &.sticky {
    background-color: white;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  @media (max-width: 868px) {
    padding: 1rem 2rem;
  }
`;

const DesktopNavLinks = styled.nav`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: space-between;
  margin: 0 2rem;

  @media (max-width: 868px) {
    display: none;
  }
`;

const MobileNavLinks = styled.nav`
  display: none;

  @media (max-width: 868px) {
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 5rem;
    left: 0;
    right: 0;
    background: white;
    z-index: 999;
    padding: 1rem 2rem;
    margin-top: 2rem;
  }
`;

const BurgerMenu = styled.div`
  display: none;

  @media (max-width: 868px) {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
`;

const NavLinks = styled.div`
  display: inline-block;

  @media (max-width: 868px) {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const NavLink = styled.a`
  font-size: 1rem !important;
  margin: 0 1rem;
  font-weight: 600;
  letter-spacing: 0em !important;
  transition: all 0.3s ease;
  padding-bottom: 0.25rem;
  border-bottom: 2px solid transparent;
  text-decoration: none;
  color: #131313 !important;
  font-family: "Poppins" !important;

  &:hover,
  &:focus {
    border-color: #000;
    color: #e2c7a6;
  }

  @media (max-width: 868px) {
    font-size: 1.25rem;
    padding: 0.75rem 0;
    border-bottom: none;
    margin: 0.4rem 0;
  }
`;

const PrimaryLink = styled(NavLink)`
  @media (min-width: 1024px) {
    margin-left: 0;
    margin-right: 0;
  }

  padding: 0.75rem 2rem;
  border-radius: 50%;
  background-color: #3b82f6;
  color: #f3f4f6;
  border-bottom: 0;

  &:hover,
  &:focus {
    background-color: #1d4ed8;
    color: #e5e7eb;
    outline: none;
    box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.6);
  }

  @media (max-width: 868px) {
    padding: 1rem;
    border-radius: 50px;
  }
`;

const LogoLink = styled(NavLink)`
  display: flex;
  align-items: center;
  font-weight: 900;
  border-bottom: 0;
  font-size: 1.5rem;
  margin-left: 0;

  img {
    max-width: 100%;
    max-height: 100%;
    width: 10rem;
    height: auto;

    @media (max-width: 868px) {
      width: 8rem;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: inline-block;
  overflow: hidden;
  border-radius: 0;
`;

const FillButton = styled(Button)`
  position: relative;
  background: transparent !important;
  border-radius: 0 !important;
  border: 2px solid #131313 !important;
  color: #131313 !important;
  font-family: "Poppins", sans-serif !important;
  padding: 0.5rem 1rem !important;
  overflow: hidden;
  transition: color 0.3s ease !important;
  z-index: 1;

  &:hover {
    color: #000 !important;
  }

  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: #131313 !important;
    transition: left 0.3s ease !important;
    z-index: -1;
  }

  &:hover:before {
    left: 0 !important;
  }

  i {
    margin-left: 0.5rem !important;
    font-size: 1rem !important;
    transition: color 0.3s ease !important;
  }

  &:hover i {
    color: #fff !important;
  }
`;

const NavBar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Header className={isSticky ? "sticky" : ""}>
      <LogoLink href="/">
        <img style={{ width: "6rem" }} src={BrandLogo} alt="logo" />
      </LogoLink>
      <DesktopNavLinks>
        <NavLinks>
          <NavLink href="/#intro">About</NavLink>
          <NavLink href="/services">Services</NavLink>
          {/* <NavLink href="/#people">People</NavLink> */}
          {/* <NavLink href="/#">Blog</NavLink> */}

          <NavLink href="/career">Career</NavLink>
        </NavLinks>
        <NavLinks>
          <ButtonWrapper>
            <a href="/#contactus">
              <FillButton size="md">
                <Text style={{ zIndex: 1 }}>
                  CONTACT US
                  <i className="fa-solid fa-chevron-right"></i>
                </Text>
              </FillButton>
            </a>
          </ButtonWrapper>
        </NavLinks>
      </DesktopNavLinks>

      <BurgerMenu onClick={toggleMenu}>
        {isMenuOpen ? (
          <IconX size={24} color="#131313" />
        ) : (
          <IconMenu2 size={24} color="#131313" />
        )}
      </BurgerMenu>

      {isMenuOpen && (
        <MobileNavLinks>
          <NavLinks>
            <NavLink href="/#intro">About</NavLink>
            <NavLink href="/services">Services</NavLink>
            {/* <NavLink href="/#people">People</NavLink> */}
            {/* <NavLink href="/#">Blog</NavLink> */}

            <NavLink href="/career">Career</NavLink>
          </NavLinks>
          <NavLinks>
            <ButtonWrapper>
              <a href="/#contactus">
                <FillButton size="md">
                  <Text style={{ zIndex: 1 }}>
                    CONTACT US
                    <i className="fa-solid fa-chevron-right"></i>
                  </Text>
                </FillButton>
              </a>
            </ButtonWrapper>
          </NavLinks>
        </MobileNavLinks>
      )}
    </Header>
  );
};

export default NavBar;
