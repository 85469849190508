import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from "@mantine/core"; // Import Mantine Modal
import { IconCheck } from "@tabler/icons-react"; // For the checkmark icon

// Wrapper for the contact section
const ContactWrapper = styled.section`
  display: flex;
  justify-content: space-around;
  padding: 4rem;
  background-color: white;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 2rem;
  }
`;

// Form wrapper
const FormWrapper = styled.div`
  max-width: 450px;
`;

// Title
// const Title = styled.h1`
//   font-size: 32px;
//   margin-bottom: 1rem;
//   color: #000;
// `;

// // Description
// const Description = styled.p`
//   font-size: 16px;
//   margin-bottom: 2rem;
//   color: #555;
// `;

// Contact Methods (links to chat, email, etc.)
const ContactMethods = styled.div`
  margin-bottom: 2rem;

  a {
    display: block;
    margin-bottom: 0.5rem;
    font-size: 16px;
    color: #e2c7a6;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

// Input fields style
const InputField = styled.input`
  width: 100%;
  padding: 0.75rem;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;

  &:focus {
    border-color: #e2c7a6;
    outline: none;
  }
`;

// Services checkboxes
const ServicesSection = styled.div`
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
`;

const CheckboxLabel = styled.label`
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-bottom: 1rem;

  input {
    margin-right: 1rem;
  }
`;

const MapWrapper = styled.div`
  width: 500px;
  height: 700px;
  background: #ddd; // Placeholder background for map
  border-radius: 5px;
  @media (max-width: 768px) {
    border-radius: 5px;
    width: 100%;
    height: 400px;
    margin-top: 2rem;
  }
`;

// Submit button
const SubmitButton = styled.button`
  background-color: #e2c7a6;
  color: #fff;
  border: none;
  padding: 1rem 2rem;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;

  &:hover {
    background-color: #bc9f8b;
  }
`;

//modal
const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: none; /* Removed shadow */
  text-align: center;
  position: relative;
`;
// Success Icon Styling
const SuccessIcon = styled(IconCheck)`
  color: #6bc47d;
  font-size: 48px;
  margin-bottom: 1.5rem;
`;

// Title Styling
const Title = styled.h2`
  font-size: 24px;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

// Description Styling
const Description = styled.p`
  font-size: 16px;
  color: #777;
  margin-bottom: 1.5rem;
`;

// Green Background Styling for the entire screen
const ModalBackground = styled(Modal)`
  .mantine-Modal-content {
    background-color: transparent; // Make the background transparent
    border-radius: 20px;
    box-shadow: none; // Removed shadow
  }
  .mantine-Modal-close {
    background-color: white; // Transparent background for the close button
    color: #ff6961;
    z-index: 1;
    border-radius: 50%;
    position: "absolute";
    top: 2.2rem;
  }

  .mantine-Modal-header {
    background-color: transparent;
  }
`;

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    services: [],
  });
  const [modalOpened, setModalOpened] = useState(false); // Modal state for thank you popup

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCheckboxChange = (e) => {
    const { checked, value } = e.target;
    setFormData((prevData) => {
      const updatedServices = checked
        ? [...prevData.services, value]
        : prevData.services.filter((service) => service !== value);
      return { ...prevData, services: updatedServices };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_EXCEL_SCRIPT_LINK;

    const submissionData = {
      ...formData,
      services: formData.services.join(", "), // Convert array to a string
    };

    try {
      await fetch(url, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify(submissionData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Clear the form after submission
      setFormData({
        name: "",
        email: "",
        number: "",
        services: [],
      });

      // Open the thank you modal
      setModalOpened(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      <ContactWrapper>
        <FormWrapper>
          <Title>Get in touch</Title>
          <Description>
            We’re here to help. Chat to our friendly team 24/7 and get set up
            and ready to go in just 5 minutes.
          </Description>

          <ContactMethods>
            <a href="#">Shoot us an email</a>
            <a href="#">Message us on Instagram</a>
          </ContactMethods>

          <form onSubmit={handleSubmit}>
            <InputField
              type="text"
              name="name"
              placeholder="Name"
              value={formData.name}
              onChange={handleChange}
            />
            <InputField
              type="email"
              name="email"
              placeholder="Email"
              value={formData.email}
              onChange={handleChange}
            />
            <InputField
              type="tel"
              name="number"
              placeholder="+971 (555) 00-0000"
              value={formData.number}
              onChange={handleChange}
            />

            {/* Services Checkboxes */}
            <ServicesSection>
              <div>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Brokerage Solutions"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes("Brokerage Solutions")}
                  />
                  Brokerage Solutions
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Property Advisory"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes("Property Advisory")}
                  />
                  Property Advisory
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Market Analysis"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes("Market Analysis")}
                  />
                  Market Analysis
                </CheckboxLabel>
              </div>

              <div>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Legal and Documentation Assistance"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes(
                      "Legal and Documentation Assistance"
                    )}
                  />
                  Legal and Documentation Assistance
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Content Creation"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes("Content Creation")}
                  />
                  Content Creation
                </CheckboxLabel>
                <CheckboxLabel>
                  <input
                    type="checkbox"
                    value="Strategy & Consulting"
                    onChange={handleCheckboxChange}
                    checked={formData.services.includes(
                      "Strategy & Consulting"
                    )}
                  />
                  Strategy & Consulting
                </CheckboxLabel>
              </div>
            </ServicesSection>

            <SubmitButton type="submit">Send message</SubmitButton>
          </form>
        </FormWrapper>

        <MapWrapper>
          {/* Embed Google Map here */}
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d529415.1409350843!2d54.89783917068263!3d25.076280453523296!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e5f43496ad9c645%3A0xbde66e5084295162!2sDubai!5e1!3m2!1sen!2sae!4v1725789756275!5m2!1sen!2sae"
            width="100%"
            height="100%"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          ></iframe>
        </MapWrapper>
      </ContactWrapper>

      {/* Thank you Modal */}
      <ModalBackground
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        withCloseButton
      >
        <StyledModalContent>
          {/* Success Icon */}
          <SuccessIcon size={80} strokeWidth={1.5} />

          {/* Title */}
          <Title>Thank You!</Title>

          {/* Description */}
          <Description>
            Pull up a chair, sit back, and relax we will reach back to you!
          </Description>
        </StyledModalContent>
      </ModalBackground>
    </>
  );
};

export default Contact;
