import React, { useState, useEffect } from "react";
import styled from "styled-components";
import cover2 from "../../assets/videos/cover2.mp4";
import { Text, Modal } from "@mantine/core";
import ContactUs from "../../components/contactUs";
import Information from "../../components/information";
import Services from "../../components/services";
import Founders from "../../components/founders";
import Testimonials from "../../components/testimonials";
import Whyus from "../../components/whyus";
import Brands from "../../components/brands";

const BackgroundWrapper = styled.div`
  position: relative;
  height: 100vh;
  overflow: hidden; /* Ensures the video stays contained */
`;

const VideoBackground = styled.video`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the video covers the area without distorting */
  z-index: -1; /* Keep the video behind the content */
  overflow: hidden;
`;

// Styled component for responsiveness using Flexbox
const FlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding-top: 12rem;
  height: 100%; /* Full height to center contents vertically */
  position: absolute;
  width: 80%;
  margin-left: 5rem;

  @media (max-width: 768px) {
    padding-top: 18rem;
    margin-left: 0rem;
    align-items: center;
    margin: 0 2rem;
  }
`;

const FlexText1 = styled(Text)`
  font-family: "Poppins";
  font-size: 5rem;
  font-weight: 600;
  color: #1a1a1a;
  letter-spacing: -0.05em;
  margin: 0; /* Removes default margins */

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const FlexText2 = styled(Text)`
  font-family: "Paris Avenue";
  font-size: 5rem;
  font-weight: 600;
  color: #1a1a1a;
  display: flex;
  letter-spacing: 0em;
  ${"" /* margin-top: -0.8em;  */}
  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const Landing = () => {
  const [modalOpened, setModalOpened] = useState(true);

  // Effect to automatically open modal when the page loads
  useEffect(() => {
    setModalOpened(true);
  }, []);
  return (
    <div>
      <BackgroundWrapper>
        <VideoBackground src={cover2} autoPlay loop muted playsInline />
        <FlexContainer>
          <FlexText1>Navigating markets,</FlexText1>
          <FlexText2 style={{ marginTop: "1rem" }}>Driving growth</FlexText2>
        </FlexContainer>
      </BackgroundWrapper>
      <Information id="intro" />
      {/* <Founders id="founders" /> */}
      <Services id="services" />
      <Brands id="brands" />
      <Testimonials id="testi" />
      <Whyus id="whyus" />
      <ContactUs id="contactus" />

      <Modal
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        withCloseButton
        size="lg" // Adjust the size as needed
        overlayBlur={3}
        overlayColor="rgba(0, 0, 0, 0.6)" // Semi-transparent overlay
        transition="fade"
        transitionDuration={300}
        styles={{
          modal: {
            borderRadius: "20px",
            background: "#fff", // Set modal background color
          },
        }}
      >
        {/* Embed ContactUs component */}
        <ContactUs />
      </Modal>
    </div>
  );
};

export default Landing;
