import React, { useState } from "react";
import styled from "styled-components";
import { Text, Button, Avatar, Group, Stack, Paper } from "@mantine/core";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin: 3rem 1rem;
  @media (min-width: 868px) {
    flex-direction: row;
    justify-content: space-between;
    margin: 3rem 10rem;
  }
`;

const LeftColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  @media (min-width: 868px) {
    margin-bottom: 0;
  }
`;

const RightColumn = styled.div`
  flex: 2;
`;

const StyledButton = styled(Button)`
  width: 100px;
  height: 50px;
  border-radius: 20px;
  background-color: ${(props) =>
    props.isHovered ? "#e2c7a6 !important" : "transparent"};
  color: ${(props) => (props.isHovered ? "white" : "black")};
  border-color: ${(props) => (props.isHovered ? "violet" : "black")};
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
`;

function Testimonials() {
  const [isHoveredLeft, setIsHoveredLeft] = useState(false);
  const [isHoveredRight, setIsHoveredRight] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);

  const testimonials = [
    {
      id: 1,
      text: `I had an amazing experience working with Embark Properties. From the initial consultation to closing, the team was professional, responsive, and incredibly knowledgeable. They found me the perfect villa within my budget and handled all the paperwork seamlessly. Highly recommend for anyone looking for personalized and attentive service!`,
      author: "RathinRaj",
      position: "Exia Tech",
      avatar: "https://randomuser.me/api/portraits/men/32.jpg",
    },
    {
      id: 2,
      text: `I contacted Embark Properties for assistance in finding an industrial property for my logistics business. Their understanding of the market and attention to my requirements was outstanding. They provided a range of options and helped negotiate a deal that fit perfectly with my business needs. I couldn’t be more satisfied with their service.`,
      author: "SamJonath",
      position: "ExiaTech",
      avatar: "https://randomuser.me/api/portraits/men/34.jpg",
    },
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonials.length - 1 : prevIndex - 1
    );
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <Container id="people">
      <LeftColumn>
        <Text
          weight={700}
          style={{ fontSize: "2rem", fontWeight: "500", fontFamily: "Poppins" }}
        >
          Why
        </Text>
        <Text
          weight={700}
          style={{
            fontSize: "3rem",
            fontWeight: "500",
            fontFamily: "Paris Avenue",
            marginTop: "-1rem",
          }}
        >
          Embark
        </Text>
        <Text
          weight={700}
          style={{
            fontSize: "3rem",
            fontWeight: "500",
            fontFamily: "Paris Avenue",
            marginTop: "-2rem",
          }}
        >
          Properties
        </Text>
        <Group position="apart" align="center" spacing="xl">
          <StyledButton
            onClick={handlePrev}
            onMouseEnter={() => setIsHoveredLeft(true)}
            onMouseLeave={() => setIsHoveredLeft(false)}
            isHovered={isHoveredLeft}
          >
            <i className="fa fa-arrow-left"></i>
          </StyledButton>
          <StyledButton
            onClick={handleNext}
            onMouseEnter={() => setIsHoveredRight(true)}
            onMouseLeave={() => setIsHoveredRight(false)}
            isHovered={isHoveredRight}
          >
            <i className="fa fa-arrow-right"></i>
          </StyledButton>
        </Group>
      </LeftColumn>
      <RightColumn>
        <Paper
          shadow="sm"
          radius="md"
          style={{
            width: "100%",
            height: "100%",
            border: "1px solid black",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1.5rem",
          }}
        >
          <Text
            size="sm"
            style={{
              color: "#464545",
              fontFamily: "Poppins",
              fontWeight: "400",
              fontSize: "1.2rem",
            }}
          >
            "{testimonials[currentIndex].text}"
          </Text>
          <Group mt="md" style={{ alignSelf: "flex-start" }}>
            <Avatar
              src={testimonials[currentIndex].avatar}
              alt={testimonials[currentIndex].author}
            />
            <Stack spacing={0} style={{ gap: "0" }}>
              <Text size="sm" weight={500}>
                {testimonials[currentIndex].author}
              </Text>
              <Text size="xs" color="gray">
                {testimonials[currentIndex].position}
              </Text>
            </Stack>
          </Group>
        </Paper>
      </RightColumn>
    </Container>
  );
}

export default Testimonials;
