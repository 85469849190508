import React, { useState } from "react";
import styled from "styled-components";
import { Text } from "@mantine/core";
import {
  IconUserCheck,
  IconChartLine,
  IconTrendingUp,
  IconFileCheck,
} from "@tabler/icons-react";

const OuterContainer = styled.div`
  color: white;
  background-color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 2rem;
  margin: 6rem 0;
`;

// Container for all cards
const CardContainer = styled.div`
  display: flex;
  justify-content: space-around;
  padding: 2rem;
  background-color: #000; /* Dark background */

  /* Media query for smaller screens */
  @media (max-width: 868px) {
    flex-direction: column;
    align-items: center;
  }
`;

// Individual Card styles
const Card = styled.div`
  background-color: ${(props) => (props.highlight ? "#e2c7a6" : "transparent")};
  border-radius: 15px;
  padding: 2rem;
  width: 20%;
  box-shadow: ${(props) =>
    props.highlight ? "0px 8px 20px rgba(0,0,0,0.1)" : "none"};
  text-align: center;
  border: ${(props) => (props.highlight ? "none" : "1px solid #333")};
  transition: all 0.3s ease;

  &:hover {
    background-color: ${(props) => (props.highlight ? "#e0e4e8" : "#1c1c1c")};
    cursor: pointer;
  }

  /* Responsive styles for smaller screens */
  @media (max-width: 868px) {
    width: 90%; /* Take almost full width of the screen */
    margin-bottom: 1.5rem;
  }
`;

// Icon styles
const Icon = styled.div`
  font-size: 2rem;
  margin-bottom: 1rem;
  color: ${(props) => (props.highlight ? "#000" : "#fff")};
`;

// Title styles
const Title = styled.h3`
  color: ${(props) => (props.highlight ? "#000" : "#fff")};
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
`;

// Description styles
const Description = styled.p`
  color: ${(props) => (props.highlight ? "#666" : "#ccc")};
  font-size: 0.9rem;
`;

const Whyus = () => {
  const [selectedCard, setSelectedCard] = useState(0); // State to track selected card

  // Function to handle card click
  const handleCardClick = (cardIndex) => {
    setSelectedCard(cardIndex);
  };

  return (
    <OuterContainer id="whyus">
      <Text
        weight={700}
        style={{
          fontSize: "3rem",
          fontWeight: "500",
          fontFamily: "Paris Avenue",
          marginTop: "1.5rem",
        }}
      >
        Why Us?
      </Text>

      <CardContainer>
        {/* Card 1 */}
        <Card highlight={selectedCard === 0} onClick={() => handleCardClick(0)}>
          <Icon highlight={selectedCard === 0}>
            <IconUserCheck style={{ fontSize: "2rem" }} />
          </Icon>
          <Title highlight={selectedCard === 0}>
            "Personalized Guidance for All Your Property Needs"
          </Title>
          <Description highlight={selectedCard === 0}>
            Offer personalized brokerage services that match each client's
            goals, whether they're buying, selling, or investing.
          </Description>
        </Card>

        {/* Card 2 */}
        <Card highlight={selectedCard === 1} onClick={() => handleCardClick(1)}>
          <Icon highlight={selectedCard === 1}>
            <IconChartLine style={{ fontSize: "2rem" }} />
          </Icon>
          <Title highlight={selectedCard === 1}>
            "Expert Advice for Informed Decisions"
          </Title>
          <Description highlight={selectedCard === 1}>
            Provide insights into property investments with an emphasis on
            making smart, strategic decisions.
          </Description>
        </Card>

        {/* Card 3 */}
        <Card highlight={selectedCard === 2} onClick={() => handleCardClick(2)}>
          <Icon highlight={selectedCard === 2}>
            <IconTrendingUp style={{ fontSize: "2rem" }} />
          </Icon>
          <Title highlight={selectedCard === 2}>
            "In-Depth Market Analysis for a Competitive Edge"
          </Title>
          <Description highlight={selectedCard === 2}>
            Utilize deep market knowledge and analysis to help clients
            capitalize on the best opportunities.
          </Description>
        </Card>

        {/* Card 4 */}
        <Card highlight={selectedCard === 3} onClick={() => handleCardClick(3)}>
          <Icon highlight={selectedCard === 3}>
            <IconFileCheck style={{ fontSize: "2rem" }} />
          </Icon>
          <Title highlight={selectedCard === 3}>
            "Seamless Legal and Documentation Support"
          </Title>
          <Description highlight={selectedCard === 3}>
            Ensure smooth and worry-free property deals with thorough legal and
            documentation assistance.
          </Description>
        </Card>
      </CardContainer>
    </OuterContainer>
  );
};

export default Whyus;
