import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ServiceImg from "../../assets/images/servicepageimg.png";

// Styled components for styling the layout
const PageWrapper = styled.div`
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f9fa;
  color: #333;
`;

const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1200px;
  align-items: center;

  @media (min-width: 868px) {
    flex-direction: row;
  }
`;

const Number = styled.div`
  font-size: 15rem;
  font-weight: bold;
  position: relative;
  color: #e2c7a6;
  margin-right: 20px;
  flex-shrink: 0;
  flex: 1.5;
  justify-content: center;
  display: flex;

  @media (min-width: 868px) {
    font-size: 15rem;
  }
`;

const Content = styled.div`
  flex: 2;
`;

const Title = styled.h2`
  font-size: 24px;
  margin-bottom: 10px;
  font-family: "Paris avenue";
  position: relative;
  padding-bottom: 10px;

  &::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 4px;
    background-color: #e2c7a6;
    transition: width 0.8s ease;
  }

  &.visible::after {
    width: 100%;
  }

  @media (min-width: 868px) {
    font-size: 28px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  line-height: 1.6;
  font-family: "poppins";
  ${"" /* text-align: center; */}
`;

const List = styled.ul`
  font-size: 16px;
  line-height: 1.6;
  padding-left: 20px;
  margin-top: 10px;
  font-family: "poppins";
`;

const ListItem = styled.li`
  margin-bottom: 5px;
  font-family: "poppins";
  font-size: 0.9rem;
`;

const ServiceItems = styled.div`
  width: 80%;
  margin: 0 auto;
`;

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0 2rem 0;
  font-family: "Paris avenue";
`;

const Image = styled.img`
  flex: 1;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  margin-top: 20px;

  @media (min-width: 868px) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const HeadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  width: 80%;
  margin: 0 auto;

  @media (min-width: 868px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const HeadTextWrapper = styled.div`
  flex: 1;
  padding: 20px;
`;

const HeadTitle = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const HeadSubtitle = styled.h3`
  font-size: 1.5rem;
  color: gray;
  margin-bottom: 20px;
`;

const HeadDescription = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
`;

const AnimatedTitle = ({ children }) => {
  const titleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true); // Add 'visible' class when in view
        } else {
          setIsVisible(false); // Remove 'visible' class when out of view
        }
      },
      {
        threshold: 0.1, // Adjust threshold as needed
      }
    );

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <Title ref={titleRef} className={isVisible ? "visible" : ""}>
      {children}
    </Title>
  );
};

const ServiceItem = ({ number, title, description, points }) => (
  <SectionWrapper>
    <Number>{number}</Number>
    <Content>
      <AnimatedTitle>{title}</AnimatedTitle>
      <Description>{description}</Description>
      {points && (
        <List>
          {points.map((point, index) => (
            <ListItem key={index}>{point}</ListItem>
          ))}
        </List>
      )}
    </Content>
  </SectionWrapper>
);

const ServicesPage = () => {
  return (
    <PageWrapper>
      <Header>Services</Header>
      <HeadSection>
        <HeadTextWrapper>
          <HeadTitle>
            <span style={{ fontFamily: "Paris avenue" }}>
              The Embark Properties
            </span>
          </HeadTitle>
          <HeadSubtitle>Take a look at our list of services</HeadSubtitle>
          <HeadDescription>
            At{" "}
            <span style={{ fontFamily: "Paris avenue" }}>
              The Embark Properties
            </span>
            , we provide a comprehensive range of services designed to meet all
            your real estate needs. From finding your dream property to
            navigating the legal complexities of real estate transactions, our
            team is here to assist you at every step. Explore our services below
            to see how we can help you make the most of your real estate
            journey.
          </HeadDescription>
        </HeadTextWrapper>
        <Image src={ServiceImg} alt="team image" />
      </HeadSection>

      <ServiceItems>
        <ServiceItem
          number="01"
          title="Property Search & Acquisition"
          description="We specialize in helping clients find the perfect property that meets their unique needs, whether residential, commercial, or investment properties."
          points={[
            "Residential Properties (Houses, Apartments, Villas)",
            "Commercial Spaces (Offices, Retail Units)",
            "Investment Opportunities (Land, Plots, Developments)",
            "Property Showings & Virtual Tours",
            "Comprehensive Market Analysis",
            "Negotiation Support for Best Deals",
          ]}
        />
        <ServiceItem
          number="02"
          title="Real Estate Brokerage Services"
          description="Our experienced brokers ensure that you receive expert guidance throughout the buying and selling process."
          points={[
            "Listing Your Property for Sale",
            "Buyer Representation Services",
            "Price Negotiation and Deal Structuring",
            "Marketing & Promotion of Properties",
            "Property Valuation Assistance",
            "Comparative Market Analysis Reports",
          ]}
        />
        <ServiceItem
          number="03"
          title="Property Leasing & Rentals"
          description="We assist both landlords and tenants in finding the right rental property or leasing agreement."
          points={[
            "Residential Rentals (Houses, Apartments)",
            "Commercial Leasing (Offices, Retail Units, Warehouses)",
            "Tenant Representation Services",
            "Lease Agreement Negotiation",
            "Property Viewing & Consultation",
            "Long-term and Short-term Rental Management",
          ]}
        />
        <ServiceItem
          number="04"
          title="Legal Assistance & Documentation"
          description="Navigating the legal aspects of real estate can be complex, but with our legal support services, you can proceed with confidence."
          points={[
            "Contract Drafting and Review",
            "Property Transfer Services",
            "Title Search and Verification",
            "Mortgage and Loan Processing",
            "Power of Attorney Arrangements",
            "Compliance with Real Estate Regulations",
          ]}
        />
        <ServiceItem
          number="05"
          title="Property Management"
          description="Managing your property has never been easier with our expert property management services."
          points={[
            "Tenant Screening & Onboarding",
            "Rent Collection & Disbursement",
            "Property Inspections & Maintenance",
            "Eviction Services (if necessary)",
            "Financial Reporting and Tax Assistance",
            "Renovation & Repair Management",
          ]}
        />
        <ServiceItem
          number="06"
          title="Real Estate Investment Advisory"
          description="For clients interested in real estate investments, we provide tailored advisory services that help you maximize your returns."
          points={[
            "Investment Property Search",
            "Risk Assessment & Feasibility Studies",
            "Portfolio Diversification Strategies",
            "Real Estate Market Trends and Forecasting",
            "Asset Management",
            "Financing and Capital Structuring",
          ]}
        />
        <ServiceItem
          number="07"
          title="Project Development & Construction Advisory"
          description="From planning to execution, we offer advisory services for real estate developers and construction projects."
          points={[
            "Land Acquisition & Development Feasibility",
            "Site Planning and Design",
            "Vendor & Contractor Selection",
            "Construction Management Support",
            "Project Financing and Budgeting",
            "Risk Management & Permitting",
          ]}
        />
        <ServiceItem
          number="08"
          title="Relocation & Moving Services"
          description="Relocating can be stressful, but we make the process easier with our complete relocation services."
          points={[
            "Relocation Consulting and Planning",
            "Property Sourcing and Lease Negotiation",
            "Moving Coordination (Packing & Transport)",
            "Post-Move Support (Utilities, Local Services)",
            "Temporary Housing Arrangements",
            "Cross-border Relocation Support",
          ]}
        />
        <ServiceItem
          number="09"
          title="Property Marketing Services"
          description="We help property owners attract the right buyers or tenants through effective marketing strategies."
          points={[
            "Professional Photography & Virtual Tours",
            "Online Property Listings",
            "Social Media Marketing Campaigns",
            "Email Marketing and Newsletters",
            "SEO for Property Listings",
            "Targeted Ad Campaigns",
          ]}
        />
        <ServiceItem
          number="10"
          title="Mortgage & Financial Services"
          description="Our financial experts assist clients with securing the best mortgage or loan options for their real estate investments."
          points={[
            "Mortgage Pre-Approval Assistance",
            "Loan Comparison and Rate Analysis",
            "Refinancing Options",
            "Financial Planning for Real Estate Purchases",
            "Tax Optimization & Financial Advisory",
            "Liaising with Banks & Financial Institutions",
          ]}
        />
      </ServiceItems>
    </PageWrapper>
  );
};

export default ServicesPage;
