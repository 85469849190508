// import React from "react";
// import styled from "styled-components";

// const Wrapper = styled.div`
//   padding: 20px;
//   background: linear-gradient(135deg, #1b1b1b, #444444);
//   max-width: 80%;
//   width: 100%;
//   box-sizing: border-box;
//   margin: 2rem auto;
//   border-radius: 1%;
//   display: flex;
//   align-items: center;
//   justify-content: space-around; /* Adjusted */
//   flex-wrap: wrap;
// `;

// const ContentWrapper = styled.div`
//   text-align: left;
//   flex: 1; /* Takes up more space */
//   padding-right: 20px;
// `;

// const ProfilesWrapper = styled.div`
//   display: flex;
//   flex-wrap: wrap;
//   justify-content: flex-end; /* Align to the right */
//   gap: 40px; /* Increased gap for more space between cards */
//   margin-top: 30px;
//   flex: 1;
// `;

// const ProfileCard = styled.div`
//   background: ${({ bgColor }) => bgColor || "#ccc"};
//   padding: 20px;
//   border-radius: 20px;
//   width: 200px;
//   text-align: center;
//   box-sizing: border-box;
// `;

// const ProfileImage = styled.img`
//   border-radius: 50%;
//   width: 100px;
//   height: 100px;
//   margin-bottom: 20px;
// `;

// const ProfileName = styled.h3`
//   font-size: 20px;
//   color: #000;
// `;

// const ProfileRole = styled.p`
//   font-size: 16px;
//   color: #666;
// `;

// const Header = styled.h1`
//   font-size: 36px;
//   margin-bottom: 10px;
//   color: white;
// `;

// const SubHeader = styled.h2`
//   font-size: 24px;
//   margin-bottom: 20px;
//   color: white;
// `;

// const ActionButton = styled.button`
//   background-color: white;
//   color: black;
//   border: none;
//   padding: 10px 20px;
//   font-size: 16px;
//   border-radius: 25px;
//   cursor: pointer;
//   margin-top: 20px;

//   &:hover {
//     background-color: #444;
//     color: white;
//   }
// `;

// const developers = [
//   {
//     name: "Sachin",
//     role: "Founder & CEO",
//     color: "#ffccd5",
//     img: "https://via.placeholder.com/100",
//   },
//   {
//     name: "Chris Mezy",
//     role: "Data engineer",
//     color: "#d6f7ff",
//     img: "https://via.placeholder.com/100",
//   },
// ];

// const Founders = () => (
//   <Wrapper>
//     <ContentWrapper>
//       <Header>EMBARK</Header>
//       <SubHeader>You’ve been searching for</SubHeader>
//       <ActionButton>Get In Touch</ActionButton>
//       {/* Add more content here as needed */}
//     </ContentWrapper>

//     <ProfilesWrapper>
//       {developers.map((dev) => (
//         <ProfileCard key={dev.name} bgColor={dev.color}>
//           <ProfileImage src={dev.img} alt={dev.name} />
//           <ProfileName>{dev.name}</ProfileName>
//           <ProfileRole>{dev.role}</ProfileRole>
//         </ProfileCard>
//       ))}
//     </ProfilesWrapper>
//   </Wrapper>
// );

// export default Founders;

import React from "react";
import styled from "styled-components";
import avatar1 from "../assets/images/avatar1.png";
import avatar2 from "../assets/images/avatar2.png";

// Wrapper for the entire card that centers it
// const CardWrapper = styled.div`
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   height: 100vh;
//   background-color: #f0f0f0;
// `;

// Main card container
const CardContainer = styled.div`
  background-color: #000;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  max-width: 80%;
  width: 100%;
  margin: 2rem auto;
  padding: 2rem 0 0 0;
  text-align: center;
`;

// Header for the stories section
const Header = styled.h1`
  font-size: 48px;
  margin-bottom: 16px;
  width: 30%;
  padding: 0 0 0 3rem;
`;

// Paragraph text
const Paragraph = styled.p`
  font-size: 16px;
  margin-bottom: 32px;
`;

// Button styled as per the example
const Button = styled.button`
  background-color: transparent;
  border: 1px solid #fff;
  color: #fff;
  padding: 0.5rem 1rem;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 20px;

  &:hover {
    background-color: #fff;
    color: #000;
  }
`;

// Flex container for the colored story cards
const StorySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  ${"" /* gap: 1rem; */}
  margin-top: 2rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

// Individual story card
const StoryCard = styled.div`
  background-color: ${({ bgColor }) => bgColor || "#fff"};
  padding: 1rem;
  flex: 1;
  color: #000;

  h3 {
    margin-bottom: 1rem;
    display: flex;
    justify-content: left;
    font-family: Paris Avenue;
  }

  p {
    font-size: 14px;
  }

  img {
    border-radius: 50%;
    margin-bottom: 1rem;
  }
`;

const InternalCC = styled.div`
  display: flex;
  flex-direction: row;
`;
const InternalCCContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem 5rem;
  align-items: flex-start;
  text-align: left;
  width: 60%;
`;

const Founders = () => {
  return (
    // <CardWrapper>
    <CardContainer id="founders">
      <InternalCC>
        <Header>Who Are We?</Header>
        <InternalCCContent>
          <Paragraph>
            Storytelling helps to break the stigma of mental health. We are now
            inviting people to share their stories around mental health. Tell us
            and others how you really feel.
          </Paragraph>
          <Button>Contact Us</Button>
        </InternalCCContent>
      </InternalCC>

      <StorySection>
        <StoryCard style={{ flex: "2" }} bgColor="#ffcc00">
          <h3 style={{ margin: "0" }}>Samuel Jones</h3>
          <img
            style={{ width: "20rem", height: "auto", margin: "0" }}
            src={avatar1}
            alt="Samuel Jones"
          />
        </StoryCard>
        <StoryCard style={{ flex: "2" }} bgColor="#ffb6c1">
          <h3 style={{ margin: "0" }}>Cynthia Richards</h3>
          <img
            style={{ width: "20rem", height: "auto", margin: "0" }}
            src={avatar2}
            alt="Cynthia Richards"
          />
        </StoryCard>
        <StoryCard style={{ flex: "1" }} bgColor="#87CEEB">
          <h3>Elaine Young</h3>
          <p>
            People often don’t know how to respond to my depression. They don’t
            know what to say, when all you need to do is be present and tell me
            you are here.
          </p>
        </StoryCard>
      </StorySection>
    </CardContainer>
    //</CardWrapper>
  );
};

export default Founders;
