import React from "react";
import styled from "styled-components";
import { Text } from "@mantine/core";

import azizilogo from "../assets/images/azizi-logo.png";
import nshamalogo from "../assets/images/nshama-logo.png";
import meraaslogo from "../assets/images/meraas-logo.png";
import danubelogo from "../assets/images/danube-logo.png";
import damaclogo from "../assets/images/damac-logo.png";

// Container for the entire section
const PartnerSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 4rem 0;
  width: 80%;
  margin: 0 auto;
`;

// Title styles
const Title = styled(Text)`
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
  font-family: "Paris Avenue";
`;

// Subtitle styles
const Subtitle = styled(Text)`
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
  text-align: center;
  @media (max-width: 768px) {
    margin-bottom: 1rem;
  }
  @media (max-width: 480px) {
    margin-bottom: 1rem;
  }
`;

// Marquee container
const MarqueeContainer = styled.div`
  display: flex;
  overflow: hidden;
  width: 100%;
  position: relative;
`;

// Inner container for scrolling content
const MarqueeInner = styled.div`
  display: flex;
  width: max-content;
  animation: marquee 20s linear infinite;

  @keyframes marquee {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%);
    }
  }

  /* Duplicate content to create a seamless loop */
  &:after {
    content: "";
    display: flex;
    width: max-content;
    animation: marquee 20s linear infinite;
  }

  @media (max-width: 768px) {
    animation: marquee 30s linear infinite; /* Slower on smaller screens */
    &:after {
      animation: marquee 30s linear infinite;
    }
  }
`;

// Logo styles
const Logo = styled.img`
  height: 150px;
  width: auto;
  margin: 0 2rem;
  object-fit: contain;

  @media (max-width: 768px) {
    margin: 0;
    height: 160px; /* Reduce logo height on smaller screens */
  }

  @media (max-width: 480px) {
    margin: 0;
    height: 150px; /* Even smaller for mobile */
  }
`;

const Brands = () => {
  const logos = [damaclogo, danubelogo, meraaslogo, nshamalogo, azizilogo];

  return (
    <PartnerSection id="brands">
      <Title>Our Partners</Title>
      <Subtitle>We are honored to have these amazing partners.</Subtitle>

      <MarqueeContainer>
        <MarqueeInner>
          {logos.map((logo, index) => (
            <Logo key={index} src={logo} alt={`Partner ${index}`} />
          ))}
          {/* Repeat logos to ensure seamless looping */}
          {logos.map((logo, index) => (
            <Logo
              key={`duplicate-${index}`}
              src={logo}
              alt={`Partner ${index}`}
            />
          ))}
        </MarqueeInner>
      </MarqueeContainer>
    </PartnerSection>
  );
};

export default Brands;
