import React from "react";
import styled from "styled-components";

import { IconCopyright } from "@tabler/icons-react";
import logo from "../../assets/images/Icon.png";
import footerimage from "../../assets/images/footerimage.png";

// Footer Wrapper
// const FooterWrapper = styled.footer`
//   background-color: #f5f7fa;
//   padding: 2rem 3rem;
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   border-top: 1px solid #e0e0e0;
//   font-family: Arial, sans-serif;

//   @media (max-width: 768px) {
//     flex-direction: column;
//     text-align: center;
//   }
// `;

const FooterWrapper = styled.footer`
  background-color: #f5f7fa;
  padding: 2rem 3rem;
  font-family: Arial, sans-serif;
  border-bottom: 10px solid black;
`;

// Left Section with contact details
const LeftSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 768px) {
    align-items: center;
  }

  img {
    width: 50px;
    height: 50px;
    margin-bottom: 10px;
  }
`;

const ContactDetails = styled.div`
  font-size: 14px;
  color: #333;
  margin-bottom: 1rem;
`;

const ContactItem = styled.div`
  margin-bottom: 0.5rem;
`;

// Right Section with buttons
const RightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  @media (max-width: 768px) {
    align-items: center;
    margin-top: 2rem;
  }

  button {
    margin-right: 1rem;
  }
`;

// Button styled component
const Button = styled.button`
  background-color: ${({ primary }) => (primary ? "#6ABD4B" : "transparent")};
  color: ${({ primary }) => (primary ? "#fff" : "#000")};
  border: ${({ primary }) => (primary ? "none" : "1px solid #000")};
  padding: 0.8rem 1.2rem;
  border-radius: 24px;
  cursor: pointer;
  font-size: 16px;
  margin: 0 0.5rem;

  &:hover {
    background-color: ${({ primary }) => (primary ? "#5AA33F" : "#f0f0f0")};
  }
`;

// Platform Icons Section
const PlatformSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
`;

const PlatformItem = styled.div`
  margin: 0 0.5rem;
  display: flex;
  justify-content: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 0.5rem;
  }

  span {
    font-size: 14px;
    margin-left: 0.5rem;
  }
`;

// Horizontal Divider
const Divider = styled.hr`
  width: 100%;
  border: none;
  border-top: 1px solid #e0e0e0;
  margin: 2rem 0;
`;

const TradeLiesence = styled.div`
  display: flex;
`;

const Termsandservices = styled.div`
  display: flex;
`;

// Footer Wrapper

// Newsletter Section
const NewsletterSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 2rem;
  border-bottom: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  h3 {
    font-size: 20px;
    margin-bottom: 1rem;
  }

  input {
    padding: 0.8rem;
    border: 1px solid #ccc;
    border-radius: 24px;
    margin-right: 10px;
    flex: 1;

    &:focus {
      outline: none;
    }
  }

  button {
    background-color: #6abd4b;
    color: white;
    border: none;
    padding: 0.8rem 1.2rem;
    border-radius: 24px;
    cursor: pointer;

    &:hover {
      background-color: #5aa33f;
    }
  }
`;

// Links Section (Platform, Company, Resources)
const LinksSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }
`;

const LinkGroup = styled.div`
  display: flex;
  flex-direction: column;

  h4 {
    margin-bottom: 1rem;
    font-size: 16px;
  }

  a {
    text-decoration: none;
    color: #333;
    margin-bottom: 0.5rem;
    font-size: 14px;

    &:hover {
      color: #6abd4b;
    }
  }
`;

// Bottom Section (Copyright and Terms)
const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 1rem;
  border-top: 1px solid #e0e0e0;

  @media (max-width: 768px) {
    flex-direction: column;
    text-align: center;
  }

  span {
    font-size: 14px;
  }
`;

const Footer = () => {
  return (
    <FooterWrapper>
      {/* Newsletter Section */}
      {/* <NewsletterSection>
        <h3>Join our newsletter to keep up to date with us!</h3>
        <div>
          <input type="email" placeholder="Enter your email" />
          <button>Subscribe</button>
        </div>
      </NewsletterSection> */}

      {/* Links Section */}
      <LinksSection>
        <LeftSection>
          <img src={logo} alt="Logo" />
          <h2>Embark Properties</h2>
          <p>Navigating Markets, Driving growth</p>
        </LeftSection>

        <LinkGroup style={{ alignItems: "center" }}>
          <img
            src={footerimage}
            style={{ width: "20rem", height: "auto" }}
            alt="footerimage"
          />
        </LinkGroup>

        <LinkGroup>
          <h4>Company</h4>

          <a href="/#intro">About</a>
          <a href="/#">News</a>
          <a href="/#">Blog</a>
        </LinkGroup>

        <LinkGroup>
          <h4>Resources</h4>
          <a href="/#contactus">Conact us</a>
          <a href="/career">Careers</a>
          <a href="/#people">people</a>
        </LinkGroup>
      </LinksSection>

      {/* Bottom Section */}
      <BottomSection>
        <span>© 2023 Embark Properties Inc.</span>

        <Termsandservices>
          <PlatformItem>
            <span>
              <a href="/privacypolicy">Terms of Service</a>
            </span>
          </PlatformItem>
          <PlatformItem>
            <span>
              <a href="/privacypolicy">Privacy Policy</a>
            </span>
          </PlatformItem>
          <PlatformItem>
            <span>
              <a href="/privacypolicy">Cookies</a>
            </span>
          </PlatformItem>
        </Termsandservices>
      </BottomSection>
    </FooterWrapper>
  );
};

export default Footer;
