import React from "react";
import styled from "styled-components";

const PrivacyPolicyContainer = styled.div`
  color: black;
  padding: 40px 20px;
  max-width: 80%;
  margin: 0 auto;
  border-radius: 8px;

  h1 {
    text-align: center;
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 20px;
  }

  h2 {
    font-size: 1.8rem;
    font-weight: bold;
    margin-bottom: 10px;
    border-bottom: 1px solid #5c6d8a;
    padding-bottom: 5px;
  }

  p {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }

  li {
    margin-bottom: 10px;
    font-size: 1.2rem;
    padding-left: 15px;
    position: relative;
  }

  li::before {
    content: "•";
    color: #5c6d8a;
    position: absolute;
    left: 0;
  }

  @media (max-width: 768px) {
    padding: 20px;

    h1 {
      font-size: 2.5rem;
    }

    h2 {
      font-size: 1.6rem;
    }

    p,
    li {
      font-size: 1rem;
    }
  }
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyPolicyContainer style={{ padding: "10rem 0 0 0" }}>
      <h1>Privacy Policy</h1>
      <p>
        This Privacy Policy applies to the{" "}
        <a href="/">www.theembarkproperties.com</a> website and outlines how we
        collect, use, and protect personal information, including data gathered
        through WhatsApp APIs and form submissions.
      </p>

      <h2>Information We Collect</h2>
      <p>We collect personal information in the following ways:</p>
      <ul>
        <li>
          <strong>Through Website Forms:</strong> When users submit inquiries or
          request services via forms on the website, we may collect personal
          details such as:
          <ul>
            <li>Name</li>
            <li>Email address</li>
            <li>Phone number</li>
            <li>
              Specific details about property preferences or other related
              queries.
            </li>
          </ul>
        </li>
        <li>
          <strong>Through WhatsApp:</strong> If you interact with us via
          WhatsApp (using WhatsApp APIs), we may collect personal data
          including, but not limited to:
          <ul>
            <li>Name</li>
            <li>WhatsApp contact details</li>
            <li>
              Preferences regarding property searches or real estate services.
            </li>
          </ul>
        </li>
      </ul>
      <p>
        By communicating with us via WhatsApp, you agree to the collection and
        processing of information as outlined in this Privacy Policy. We will
        use WhatsApp to answer queries and provide services based on your
        expressed interests.
      </p>

      <h2>How We Use Your Information</h2>
      <p>The information we collect is used for the following purposes:</p>
      <ul>
        <li>
          To respond to inquiries and provide requested real estate services.
        </li>
        <li>
          To process and understand user preferences and requests, whether
          collected through forms or WhatsApp.
        </li>
        <li>
          To communicate with users regarding services, properties, or
          promotions related to their interests.
        </li>
        <li>
          To improve the user experience on our website and through WhatsApp
          interactions by analyzing data to better understand customer needs.
        </li>
      </ul>

      <h2>Third-Party Services</h2>
      <p>
        We may use third-party services, such as WhatsApp, to facilitate
        communications. By engaging with us through these services, users
        acknowledge that their interactions and any personal data shared may
        also be subject to the respective third-party's privacy policies. We
        recommend reviewing the privacy policies of these platforms to
        understand how your data may be handled.
      </p>

      <h2>Cookies</h2>
      <p>
        We use cookies to enhance your experience on the website by remembering
        preferences, speeding up website functions, and analyzing traffic.
        Cookies do not give us access to your device or any personal information
        unless voluntarily provided through other methods like form submissions
        or WhatsApp communications.
      </p>

      <h2>Security</h2>
      <p>
        We take all reasonable measures to protect your personal data. Whether
        collected through forms, WhatsApp, or other means, your data is secured
        using industry-standard security protocols to prevent unauthorized
        access, misuse, or disclosure.
      </p>

      <h2>Sharing Your Information</h2>
      <p>
        We will not sell, trade, or disclose your personal information to third
        parties without your consent, except as necessary to:
      </p>
      <ul>
        <li>Fulfill legal requirements.</li>
        <li>Provide you with the services you requested.</li>
        <li>Protect the safety and rights of our users and the public.</li>
      </ul>
      <p>
        In some cases, we may share data within the theembarkproperties group to
        provide you with better services and personalized experiences.
      </p>

      <h2>Your Rights</h2>
      <p>You have the right to:</p>
      <ul>
        <li>Access the personal data we collect about you.</li>
        <li>Request correction or updates to your personal information.</li>
        <li>
          Opt out of marketing communications at any time by sending an email to
          [email address] with “Unsubscribe” in the subject line.
        </li>
      </ul>

      <h2>Changes to This Policy</h2>
      <p>
        We may update this Privacy Policy periodically to reflect changes in our
        services or legal requirements. All updates will be posted on the
        <a href="/"> www.theembarkproperties.com</a> website, and we encourage
        you to review this policy regularly. If significant changes are made, we
        will notify users via email or WhatsApp.
      </p>
      <p>
        By continuing to use our services, including interactions via WhatsApp,
        after any updates, you acknowledge and accept the revised Privacy
        Policy.
      </p>
    </PrivacyPolicyContainer>
  );
};

export default PrivacyPolicy;
