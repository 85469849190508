// Career.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import {
  IconChartHistogram,
  IconDeviceLaptop,
  IconWorld,
  IconTagsFilled,
  IconSpeakerphone,
  IconSettings,
  IconGradienter,
  IconCheck,
} from "@tabler/icons-react";
import CareerImg from "../../assets/images/careerimg.png";
import ValuesImg from "../../assets/images/valuesimg.png";
import { Modal } from "@mantine/core";

const Container = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
`;

const Header = styled.h1`
  font-size: 3rem;
  text-align: center;
  margin: 10rem 0 2rem 0;
  font-family: "Paris avenue";
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;

  @media (min-width: 868px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

const TextWrapper = styled.div`
  flex: 1;
  padding: 20px;
`;

const Title = styled.h2`
  font-size: 2rem;
  margin-bottom: 20px;
`;

const Subtitle = styled.h3`
  font-size: 1.5rem;
  color: gray;
  margin-bottom: 20px;
`;

const Description = styled.p`
  font-size: 1.2rem;
  line-height: 1.6;
`;

const Image = styled.img`
  flex: 1;
  width: 100%;
  max-width: 500px;
  border-radius: 10px;
  margin-top: 20px;

  @media (min-width: 868px) {
    margin-top: 0;
    margin-left: 20px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const SubSection = styled.div`
  padding: 20px;
`;

const SubTitle = styled.h4`
  font-size: 2rem;
  margin-bottom: 20px;
  font-family: "Paris avenue";
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 20px;
  font-size: 1.2rem;

  li {
    margin-bottom: 10px;
  }
`;

// const FormWrapper = styled.div`
//   background-color: #f9f9f9;
//   padding: 40px;
//   border-radius: 10px;
//   max-width: 600px;
//   margin: 0 auto;
// `;

// const FormTitle = styled.h3`
//   text-align: center;
//   font-size: 2rem;
//   margin-bottom: 20px;
// `;

// const Form = styled.form`
//   display: flex;
//   flex-direction: column;
//   gap: 20px;
// `;

// const Input = styled.input`
//   padding: 10px;
//   font-size: 1rem;
//   border: 1px solid #ddd;
//   border-radius: 5px;
// `;

// const Textarea = styled.textarea`
//   padding: 10px;
//   font-size: 1rem;
//   border: 1px solid #ddd;
//   border-radius: 5px;
//   height: 100px;
// `;

// const SubmitButton = styled.button`
//   padding: 15px;
//   background-color: #007bff;
//   color: white;
//   font-size: 1.2rem;
//   border: none;
//   border-radius: 5px;
//   cursor: pointer;
//   transition: background-color 0.3s ease;

//   &:hover {
//     background-color: #0056b3;
//   }
// `;

// Updated Why Work with Us Section - Centered second row boxes
const WhyUsSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
  margin: 40px 0;
`;

const WhyUsCard = styled.div`
  background-color: ${(props) => (props.highlight ? "#e2c7a6" : "#fff")};
  color: ${(props) => (props.highlight ? "#000" : "#000")};
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  flex: 1 1 calc(33% - 30px);
  max-width: 500px;

  &:hover {
    transform: translateY(-10px);
  }

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const CardIcon = styled.div`
  margin-top: 1rem;
`;

const CardTitle = styled.h3`
  font-size: 1.6rem;
  margin-bottom: 10px;
`;

const CardDescription = styled.p`
  font-size: 1rem;
`;

const Shapefill = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);

  svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 150px;
  }

  .shape-fill {
    fill: black;
  }
`;

const ValuesSection = styled.section`
  text-align: center;
  padding: 50px 20px;
  background-color: #0d0d22;
  color: #fff;
  margin-top: 50px;
`;

const ValuesHeader = styled.h2`
  font-size: 2.5rem;
  margin-bottom: 20px;
`;

const ValuesSubHeader = styled.p`
  font-size: 1.2rem;
  margin-bottom: 50px;
  color: #b3b3b3;
`;

const ValuesGrid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  max-width: 1200px;
  margin: 0 auto;
`;

const ValueCard = styled.div`
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 30px;
  border-radius: 15px;
  width: 200px;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);

  &:hover {
    transform: translateY(-10px);
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ValueTitle = styled.h3`
  font-size: 1.5rem;
  margin-bottom: 10px;
  color: #ffffff;
`;

const ValueDescription = styled.p`
  font-size: 1rem;
  color: #b3b3b3;
  text-align: center;
`;

// Example of icons or images for the cards (you can replace these with your own)
const ValueIcon = styled.div`
  font-size: 3rem;
  margin-bottom: 15px;
  color: #b3b3b3;
`;

const FormTitle = styled.h3`
  text-align: left;
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #333;
  font-family: "Paris avenue";
`;

const Form = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
  margin-bottom: 2rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;

const Label = styled.label`
  font-size: 0.9rem;
  color: #333;
  font-weight: "600";
  font-family: "poppins";
`;

const Input = styled.input`
  padding: 15px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-bottom: 1px solid #007bff;
  }
`;

const Select = styled.select`
  padding: 15px;
  font-size: 1rem;
  border: none;
  border-bottom: 1px solid #ddd;
  background-color: transparent;
  width: 100%;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border-bottom: 1px solid #007bff;
  }
`;

const FullWidthInput = styled.div`
  grid-column: 1 / 3;

  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

const CheckboxWrapper = styled.div`
  grid-column: 1 / 3;
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    grid-column: 1;
  }
`;

const CheckboxLabel = styled.label`
  margin-left: 10px;
  font-size: 0.9rem;
  color: #333;
`;

const SubmitButton = styled.button`
  padding: 15px;
  background-color: white; /* Primary button color */
  color: black;
  font-size: 1.1rem;

  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.3s ease;
  text-align: center;
  width: 100%; /* Full-width button */

  &:hover {
    background-color: black;
    color: white; /* On hover, invert colors */
    border-color: #000; /* Add border color on hover */
    border: 1px solid transparent;
  }

  &:focus {
    outline: none;
    box-shadow: 0 0 0 3px #000; /* Focus outline */
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    padding: 12px; /* Smaller padding on smaller screens */
  }
`;
//modal
const StyledModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #fff;
  border-radius: 20px;
  box-shadow: none; /* Removed shadow */
  text-align: center;
  position: relative;
`;
// Success Icon Styling
const SuccessIcon = styled(IconCheck)`
  color: #6bc47d;
  font-size: 48px;
  margin-bottom: 1.5rem;
`;
const ModalBackground = styled(Modal)`
  .mantine-Modal-content {
    background-color: transparent; // Make the background transparent
    border-radius: 20px;
    box-shadow: none; // Removed shadow
  }
  .mantine-Modal-close {
    background-color: white; // Transparent background for the close button
    color: #ff6961;
    z-index: 1;
    border-radius: 50%;
    position: "absolute";
    top: 2.2rem;
  }

  .mantine-Modal-header {
    background-color: transparent;
  }
`;

const Career = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    experience: "",
    callPermission: "Accepts with Pleasure",
    jobType: "Full Time Job",
    linkedin: "",
    age: "",
  });
  const [modalOpened, setModalOpened] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     const response = await axios.post("your-endpoint-url", formData);
  //     console.log(response.data);
  //     alert("Form submitted successfully");
  //   } catch (error) {
  //     console.error("Error submitting form", error);
  //     alert("Failed to submit the form");
  //   }
  // };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const url = process.env.REACT_APP_CAREER_EXCEL_SCRIPT_LINK;

    try {
      await fetch(url, {
        method: "POST",
        mode: "no-cors",
        body: JSON.stringify(formData),
        headers: {
          "Content-Type": "application/json",
        },
      });

      // Clear the form after submission
      setFormData({
        name: "",
        email: "",
        phone: "",
        experience: "",
        callPermission: "Accepts with Pleasure",
        jobType: "Full Time Job",
        linkedin: "",
        age: "",
      });

      // Open the thank you modal
      setModalOpened(true);
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <Container>
      <Header>Career</Header>

      <Section>
        <TextWrapper>
          <Title>
            Join Our Team at{" "}
            <span style={{ fontFamily: "Paris avenue" }}>
              The Embark Properties
            </span>
          </Title>
          <Subtitle>Meet the team behind our success</Subtitle>
          <Description>
            At{" "}
            <span style={{ fontFamily: "Paris avenue" }}>
              The Embark Properties
            </span>
            , we are more than just a real estate company—we are a vibrant
            community of innovators, strategists, and professionals committed to
            shaping the future of the real estate landscape in Dubai. We pride
            ourselves on our dynamic work culture, where every voice is heard,
            and every contribution is valued.
          </Description>
        </TextWrapper>
        <Image src={CareerImg} alt="team image" />
      </Section>

      {/* Complete Why Work With Us Section */}
      <SubTitle
        style={{ textAlign: "center", marginTop: "7rem", marginBottom: "2rem" }}
      >
        Why Work With Us?
      </SubTitle>
      <WhyUsSection>
        <WhyUsCard highlight>
          <CardIcon>
            <IconChartHistogram style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Innovative Environment</CardTitle>
          <CardDescription>
            We embrace innovation and encourage out-of-the-box thinking. From
            leveraging cutting-edge technology to implementing fresh strategies,
            we empower our team to push boundaries and redefine industry
            standards.
          </CardDescription>
        </WhyUsCard>

        <WhyUsCard>
          <CardIcon>
            <IconDeviceLaptop style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Growth Opportunities</CardTitle>
          <CardDescription>
            Your growth is our priority. We invest in our team's professional
            development with continuous learning opportunities, mentorship
            programs, and career advancement paths that help you achieve your
            full potential.
          </CardDescription>
        </WhyUsCard>

        <WhyUsCard highlight>
          <CardIcon>
            <IconWorld style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Collaborative Culture</CardTitle>
          <CardDescription>
            Collaboration is at the heart of everything we do. We believe in the
            power of teamwork and foster an inclusive environment where ideas
            are freely shared, and success is celebrated together.
          </CardDescription>
        </WhyUsCard>

        <WhyUsCard>
          <CardIcon>
            <IconTagsFilled style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Work-Life Balance</CardTitle>
          <CardDescription>
            We understand the importance of balance. Our flexible work policies
            and supportive work environment ensure that you can thrive
            professionally while maintaining a healthy work-life balance.
          </CardDescription>
        </WhyUsCard>

        <WhyUsCard highlight>
          <CardIcon>
            <IconSpeakerphone style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Impactful Work</CardTitle>
          <CardDescription>
            Your work matters here. Whether it's closing a major deal or helping
            clients find their dream homes, every task you undertake directly
            impacts our company's success and the community we serve.
          </CardDescription>
        </WhyUsCard>

        <WhyUsCard>
          <CardIcon>
            <IconSettings style={{ height: "4rem", width: "4rem" }} />
          </CardIcon>
          <CardTitle>Diversity & Inclusion</CardTitle>
          <CardDescription>
            At Embark Properties, we celebrate diversity. We are committed to
            building a diverse team that reflects the vibrant culture of Dubai
            and bringing unique perspectives to the table.
          </CardDescription>
        </WhyUsCard>
      </WhyUsSection>

      <Content>
        <SubSection>
          <SubTitle style={{ textAlign: "center" }}>Our Values</SubTitle>
          <Description style={{ textAlign: "center" }}>
            Our values are the foundation of everything we do. They drive our
            commitment to excellence and guide how we work with our clients and
            each other:
          </Description>
          <Section>
            <Image src={ValuesImg} alt="team image" />
            <TextWrapper>
              <List>
                <li>
                  <strong>Integrity:</strong> We believe in doing business the
                  right way, with honesty, transparency, and a commitment to
                  ethical practices.
                </li>
                <li>
                  <strong>Excellence:</strong> We strive for excellence in every
                  project and interaction, ensuring the highest quality service
                  for our clients.
                </li>
                <li>
                  <strong>Customer Focus:</strong> Our clients are at the center
                  of everything we do. We go above and beyond to meet their
                  needs and exceed their expectations.
                </li>
                <li>
                  <strong>Innovation:</strong> We are constantly evolving, using
                  the latest technology and forward-thinking strategies to stay
                  ahead in the industry.
                </li>
                <li>
                  <strong>Sustainability:</strong> We are committed to
                  sustainable practices that positively impact the environment
                  and our community.
                </li>
              </List>
            </TextWrapper>
          </Section>
          {/* <List>
            <li>
              <strong>Integrity:</strong> We believe in doing business the right
              way, with honesty, transparency, and a commitment to ethical
              practices.
            </li>
            <li>
              <strong>Excellence:</strong> We strive for excellence in every
              project and interaction, ensuring the highest quality service for
              our clients.
            </li>
            <li>
              <strong>Customer Focus:</strong> Our clients are at the center of
              everything we do. We go above and beyond to meet their needs and
              exceed their expectations.
            </li>
            <li>
              <strong>Innovation:</strong> We are constantly evolving, using the
              latest technology and forward-thinking strategies to stay ahead in
              the industry.
            </li>
            <li>
              <strong>Sustainability:</strong> We are committed to sustainable
              practices that positively impact the environment and our
              community.
            </li>
          </List> */}
        </SubSection>
      </Content>
      <FormTitle>About You</FormTitle>
      <Form onSubmit={handleSubmit}>
        <div>
          <Label htmlFor="name">Full Name</Label>
          <Input
            type="text"
            name="name"
            id="name"
            placeholder="Enter your full name"
            value={formData.name}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="email">Email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Enter your email"
            value={formData.email}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="phone">Phone Number</Label>
          <Input
            type="text"
            name="phone"
            id="phone"
            placeholder="Enter your phone number"
            value={formData.phone}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="experience">Experience (Years)</Label>
          <Input
            type="number"
            name="experience"
            id="experience"
            placeholder="Enter your experience in years"
            value={formData.experience}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="callPermission">Can we call you?</Label>
          <Select
            name="callPermission"
            id="callPermission"
            value={formData.callPermission}
            onChange={handleChange}
            required
          >
            <option value="Accepts with Pleasure">Accepts with Pleasure</option>
            <option value="Busy, Prefer Emails">Busy, Prefer Emails</option>
            <option value="Available After Office Hours">
              Available After Office Hours
            </option>
          </Select>
        </div>

        <div>
          <Label htmlFor="jobType">Job Type</Label>
          <Select
            name="jobType"
            id="jobType"
            value={formData.jobType}
            onChange={handleChange}
            required
          >
            <option value="Full Time Job">Full Time Job</option>
            <option value="Part Time Job">Part Time Job</option>
            <option value="Internship">Internship</option>
            <option value="Contract">Contract</option>
          </Select>
        </div>

        <div>
          <Label htmlFor="linkedin">LinkedIn Profile</Label>
          <Input
            type="text"
            name="linkedin"
            id="linkedin"
            placeholder="Enter your LinkedIn profile"
            value={formData.linkedin}
            onChange={handleChange}
            required
          />
        </div>

        <div>
          <Label htmlFor="age">Age</Label>
          <Input
            type="number"
            name="age"
            id="age"
            placeholder="Enter your age"
            value={formData.age}
            onChange={handleChange}
            required
          />
        </div>

        {/* <CheckboxWrapper>
          <input type="checkbox" id="privacyPolicy" required />
          <CheckboxLabel htmlFor="privacyPolicy">
            I agree to the Privacy Policy.
          </CheckboxLabel>
        </CheckboxWrapper> */}

        <SubmitButton type="submit">Submit</SubmitButton>
      </Form>
      <ModalBackground
        opened={modalOpened}
        onClose={() => setModalOpened(false)}
        centered
        withCloseButton
      >
        <StyledModalContent>
          {/* Success Icon */}
          <SuccessIcon size={80} strokeWidth={1.5} />

          {/* Title */}
          <Title>Thank You!</Title>

          {/* Description */}
          <Description>
            Pull up a chair, sit back, and relax we will reach back to you!
          </Description>
        </StyledModalContent>
      </ModalBackground>
    </Container>
  );
};

export default Career;
